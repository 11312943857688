import { c, a as a$1 } from './chunk-7ICUGDQU.mjs';
import * as a from 'react';
import * as e from '@radix-ui/react-label';
import { cva } from 'class-variance-authority';
import { cn } from '@sajilni/utils';
import { jsx } from 'react/jsx-runtime';

var n=cva("ui-text-sm ui-font-medium ui-leading-none peer-disabled:ui-cursor-not-allowed peer-disabled:ui-opacity-70"),f=a.forwardRef((b,l)=>{var i=b,{className:r}=i,m=c(i,["className"]);return jsx(e.Root,a$1({ref:l,className:cn(n(),r)},m))});f.displayName=e.Root.displayName;

export { f as a };
