import { a as a$1, b as b$1, c, d } from './chunk-POLXFGNN.mjs';
import { a, e, f, h, i, g } from './chunk-4BLES4YN.mjs';
import { b } from './chunk-UE7CAWAC.mjs';
import { useLayoutEffect, useEffect, useState, Suspense } from 'react';
import { AlarmClock } from 'lucide-react';
import { getCookie } from '@sajilni/utils';
import { jsx, Fragment, jsxs } from 'react/jsx-runtime';

var F=typeof window!="undefined"?useLayoutEffect:useEffect,z=({initialMinute:C=0,initialSeconds:w=0,addToolTip:x,msg:y,trigger:b$2,onComplete:S},M)=>{let [o,N]=useState(C),[t,n]=useState(w),[h$1,l]=useState(!0);getCookie("eventSlug");return F(()=>{let a=setInterval(()=>{t>0&&n(t-1),t===0&&(o===0?(l(!0),clearInterval(a)):(N(o-1),n(59)));},1e3);return ()=>{clearInterval(a);}},[o,t]),jsx("div",{className:"ui-border-1 ui-flex ui-items-center ui-rounded-md ui-bg-white ui-justify-between ui-p-3 border",children:jsx(Suspense,{fallback:jsx(Fragment,{children:"loading..."}),children:o<0||o==0&&t==0?jsx(a,{open:h$1,onOpenChange:l,children:jsxs(e,{children:[jsxs(f,{children:[jsx(h,{children:"Sorry... Time's Up"}),jsx(i,{children:"The time limit gives all fans a fair shot at tickets."})]}),jsx(g,{children:jsx(b,{variant:"outlined",onClick:S,children:"Try Again Now"})})]})}):jsxs(Fragment,{children:[jsxs("div",{className:"ui-text-[#19AE67]  ui-flex ui-w-full ui-items-center ui-justify-start ui-gap-2 ",children:[jsx(AlarmClock,{className:"ui-h-5 ui-w-5 ui-text-[#19AE67]"}),jsxs("span",{className:"ui-my-auto ui-font-medium",children:[o,":",t<10?`0${t}`:t," Mins"]})]}),x?jsx(a$1,{children:jsxs(b$1,{children:[jsx(c,{children:b$2}),jsx(d,{className:"ui-text-xs",children:y})]})}):""]})})})};

export { F as a, z as b };
