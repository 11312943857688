import { a as a$1 } from './chunk-I5UQ3OEO.mjs';
import { a as a$3 } from './chunk-WBYDRUOL.mjs';
import { a as a$2 } from './chunk-RM3IPTXR.mjs';
import { a, e } from './chunk-XILWQE4U.mjs';
import { b } from './chunk-5RN7CTYC.mjs';
import { b as b$1 } from './chunk-UE7CAWAC.mjs';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';

var v=({workshops:c})=>jsx("div",{className:"ui-flex ui-flex-col ui-gap-2 ui-max-w-[88%] md:ui-max-w-95%",children:c.map((o,d)=>jsxs("div",{children:[jsx("h3",{children:o.category}),o.items.map((t,l)=>jsx(Fragment,{children:jsx(a,{children:jsxs(e,{className:"ui-flex ui-flex-col ui-gap-1 !ui-pt-6 ",children:[jsx(b,{variant:"outlined",className:"ui-max-w-[fit-content]",children:t.tag}),jsx("span",{}),jsx("h3",{children:t.title}),jsx(a$1,{name:t.speaker}),jsx(a$2,{date:t.date}),jsxs("div",{className:"ui-flex ui-justify-between",children:[jsx(a$3,{price:0,currency:"JOD"}),jsx(b$1,{buttonColor:"primary",variant:"solid",children:"Book workshop"})]})]})},l)}))]},d))});

export { v as a };
